import { Box, styled } from '@mui/material';
import { BACK_LIGHT, WHITE } from 'app/theme/colors';

export const AISummaryIntroWidgetWrapper = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: WHITE,
    borderRadius: '10px',
    padding: '44px 56px',
    gap: theme.spacing(1),
    width: '50%',
  },
}));

export const AISummaryIntroPrompt = styled(Box)({
  position: 'absolute',
  bottom: 0,
  margin: 8,
  padding: 8,
  width: 'calc(100% - 16px)',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '6px',
  backgroundColor: BACK_LIGHT,
  '& > .MuiTypography-root': {
    fontSize: '13.33px',
  },
});
