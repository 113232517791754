import React from 'react';

import { WORKBUZZ_BLUE, WORKBUZZ_ORANGE } from 'app/theme/colors';
import { WBFallbackImage } from '@workbuzz/shared-ui';
import { Typography } from '@mui/material';
import {
  AISummaryIntroPrompt,
  AISummaryIntroWidgetWrapper,
} from 'view/SummaryPage/AISummaryIntroWidget/AISummaryIntroWidget.s';

export const AISummaryIntroWidget: React.FC = () => {
  return (
    <AISummaryIntroWidgetWrapper data-testid="aiSummaryIntro">
      <WBFallbackImage
        src="/assets/ai_logo.svg"
        alt="AI logo"
        width={30}
        height={30}
        aria-hidden="true"
      />
      <Typography variant="h6" sx={{ color: WORKBUZZ_ORANGE }}>
        Introducing
      </Typography>
      <Typography variant="h5" sx={{ color: WORKBUZZ_BLUE }}>
        The Next Level of Employee Insight.
      </Typography>
      <Typography variant="body1" mb={4}>
        WorkBuzz’s People Science AI. Based on our Expert-Trained AI Model, which is built on over 1
        million hours of employee engagement expertise. Save time, without compromising the quality
        of your engagement insights and drive informed change.
      </Typography>
      <AISummaryIntroPrompt>
        <Typography variant="body2">Use prompts to left to get started</Typography>
      </AISummaryIntroPrompt>
    </AISummaryIntroWidgetWrapper>
  );
};
