import { SxProps, Theme } from '@mui/material';

export const testSummaryStyles: SxProps<Theme> = {
  width: { md: '50%' },
  '& .MuiTypography-root': {
    '& > .MuiBox-root': {
      fontSize: '16px',
      textTransform: 'lowercase', // TODO: AIS-276
      '&:first-letter': {
        textTransform: 'uppercase',
      },
    },
  },
};
