import React from 'react';
import { useSelector } from 'react-redux';

import { scrollToLastElement } from 'util/scrollToLastElement';
import { getNextAISummaryItemByTitle } from 'util/getNextAISummaryItemByTitle';
import { getNextAIAvatarItemByTitle } from 'util/getNextAIAvatarItemByTitle';

import { useAIDialogHistory } from 'shared/hooks/useAIDialogHistory';
import { Box, Typography } from '@mui/material';
import { WBButton, WBFallbackImage, WBVideoPlayer } from '@workbuzz/shared-ui';
import {
  AIDialogAnswerAILogo,
  AIDialogAnswerCard,
  AIDialogAnswerNextSection,
  AIDialogContentsWrapper,
  AIDialogParagraph,
  AIDialogQuestion,
  buttonStyles,
} from 'component/AIDialogContents/AIDialogContents.s';
import { selectAIAvatar, selectAISummary } from 'reduxStore/ai/selectors';
import { HARD_COAL } from 'app/theme/colors';
import moment from 'moment/moment';
import { AIAvatarStatusEnum } from 'api/dto/AIAvatar.dto';

export const AIDialogContents: React.FC = () => {
  const { history, addHistoryItem } = useAIDialogHistory();
  const aiSummary = useSelector(selectAISummary);
  const aiAvatar = useSelector(selectAIAvatar);

  return (
    <AIDialogContentsWrapper>
      <Typography
        data-testid="aiDialogDate"
        sx={{ textAlign: 'center', mt: 3, color: HARD_COAL }}
        variant="body2"
      >
        {moment().format('DD MMM YYYY, HH:mm')}
      </Typography>

      {history?.map(({ title, paragraph, avatarStatus, avatarUrl, nextSectionTitle }, index) => {
        const nextAIAvatarItem = getNextAIAvatarItemByTitle({ aiAvatar, aiSummary, title });
        const nextAISummaryItem = getNextAISummaryItemByTitle(aiSummary, title);
        const afterNextAISummaryItem = getNextAISummaryItemByTitle(aiSummary, nextSectionTitle);

        // Preparing the next section button click handler for each AI dialog item
        const openNextSection = () => {
          if (nextAISummaryItem && afterNextAISummaryItem) {
            addHistoryItem({
              title: nextAISummaryItem.title,
              paragraph: nextAISummaryItem.paragraph,
              avatarStatus: nextAIAvatarItem?.status ?? null,
              avatarUrl: nextAIAvatarItem?.url ?? null,
              nextSectionTitle: afterNextAISummaryItem.title,
            });
            scrollToLastElement('.aiDialogQuestion');
          }
        };

        return (
          <React.Fragment key={`aiDialogContents[${index}]`}>
            <AIDialogQuestion
              data-testid={`aiDialogQuestion[${index}]`}
              className="aiDialogQuestion"
              variant="body2"
            >
              {title}
            </AIDialogQuestion>

            <Box data-testid={`aiDialogAnswer[${index}]`} className="aiDialogAnswer">
              <AIDialogAnswerCard data-testid={`aiDialogAnswerCard[${index}]`}>
                {avatarStatus === AIAvatarStatusEnum.Completed && avatarUrl && (
                  <WBVideoPlayer
                    src={avatarUrl}
                    wrapperSx={{ borderBottomRightRadius: 0, borderBottomLeftRadius: 0 }}
                  />
                )}
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: '12px 16px' }}>
                  {paragraph.split('\n').map((paragraph, index) => (
                    <AIDialogParagraph key={index} variant="body2">
                      {paragraph}
                    </AIDialogParagraph>
                  ))}
                </Box>
              </AIDialogAnswerCard>

              <AIDialogAnswerNextSection className="aiDialogAnswerNextSection">
                <AIDialogAnswerAILogo>
                  <WBFallbackImage
                    src="/assets/ai_logo.svg"
                    alt="AI logo"
                    aria-hidden="true"
                    sx={{ margin: '2px', width: { xs: 16, md: 20 } }}
                  />
                </AIDialogAnswerAILogo>
                <WBButton
                  data-testid={`aiDialogAnswerNextSectionButton[${index}]`}
                  color="primary"
                  onClick={openNextSection}
                  size="small"
                  variant="text"
                  sx={buttonStyles}
                >
                  {nextSectionTitle}
                </WBButton>
              </AIDialogAnswerNextSection>
            </Box>
          </React.Fragment>
        );
      })}
    </AIDialogContentsWrapper>
  );
};
