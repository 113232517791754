import { Box, styled, SxProps, Theme, Typography } from '@mui/material';
import { WHITE, WORKBUZZ_BLUE } from 'app/theme/colors';
import { wbTheme } from '@workbuzz/shared-ui';

export const AIDialogContentsWrapper = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
});

export const AIDialogQuestion = styled(Typography)(({ theme = wbTheme }) => ({
  ...theme.typography.body2,
  display: 'block',
  padding: '8px 12px',
  color: WHITE,
  backgroundColor: WORKBUZZ_BLUE,
  borderRadius: '6px',
  marginLeft: '64px',
  fontSize: '12px',
  textTransform: 'lowercase',
  '&:first-letter': {
    textTransform: 'uppercase',
  },
  [theme.breakpoints.up('md')]: {
    marginLeft: '104px',
    fontSize: '13.33px',
  },
}));

export const AIDialogAnswerCard = styled(Box)(({ theme }) => ({
  backgroundColor: WHITE,
  borderRadius: '6px',
  marginLeft: '36px',
  marginRight: '24px',
  [theme.breakpoints.up('md')]: {
    marginLeft: '40px',
    marginRight: '64px',
  },
}));

export const AIDialogAnswerNextSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'end',
  gap: '8px',
  marginRight: '24px',
  [theme.breakpoints.up('md')]: {
    marginRight: '64px',
  },
}));

export const AIDialogAnswerAILogo = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: WHITE,
  borderRadius: '50%',
  minWidth: '28px',
  height: '28px',
  [theme.breakpoints.up('md')]: {
    minWidth: '32px',
    height: '32px',
  },
}));

export const AIDialogParagraph = styled(Typography)(({ theme = wbTheme }) => ({
  ...theme.typography.body2,
  fontSize: '12px',
  [theme.breakpoints.up('md')]: {
    fontSize: '13.33px',
  },
}));

export const buttonStyles: SxProps<Theme> = {
  width: '100%',
  mt: 1,
  display: 'block',
  textTransform: 'lowercase', // TODO: AIS-276
  '&:first-letter': {
    textTransform: 'uppercase',
  },
};
